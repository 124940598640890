import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Element, scroller } from "react-scroll";

import Header from './Header';
import Footer from './Footer';
import Evento from '../evento/Evento';
import Sala from '../conferencias/Sala';
import Registro from '../registro/Registro';
import EventoOmnitracsMilla from '../evento/EventoOmnitracsMilla';
import EventoInicio from '../evento/EventoInicio';
import Crucigrama from '../paginas/Crucigrama';
import Cuestionario from '../paginas/Cuestionario';
import Diploma from '../paginas/Diploma';

import RegistroIngenieria from '../registro/RegistroIngenieria';
import RegistroReformas from '../registro/RegistroReformas';
import RegistroOmnitracs from '../registro/RegistroOmnitracs';
import RegistroMegatendencias from '../registro/RegistroMegatendencias';
import RegistroAplicacionNom012 from '../registro/RegistroAplicacionNom012';
import RegistroAplicacionNom012_2da from '../registro/RegistroAplicacionNom012_2da';
import RegistroCerradoAplicacionNom012 from '../registro/RegistroCerradoAplicacionNom012';

import RecuperarContrasena from '../sesion/RecuperarContrasena';
import Login from '../registro/Login';
import Perfil from '../sesion/Perfil';
import Error from "../auth/Error";

import Demo from '../en-vivo/Demo';
import VehiculosAlta from '../en-vivo/VehiculosAlta';
import IngenieriaVehicular from '../en-vivo/IngenieriaVehicular';
import OmnitracsMilla from '../en-vivo/OmnitracsMilla';

import EventoEnvivo from '../en-vivo/EventoEnVivo';

import AgregandoValor from '../en-vivo/AgregandoValor';
import TendenciasPotenciales from '../en-vivo/TendenciasPotenciales';
import ManualPrevencion from '../en-vivo/ManualPrevencion';
import GuiaLineamientos from '../en-vivo/GuiaLineamientos';
import GestionEficiente from '../en-vivo/GestionEficiente';
import ManualPrevencionSegundo from '../en-vivo/ManualPrevencionSegundo';
import ManualPrevencionTercero from '../en-vivo/ManualPrevencionTercero';
import IndicadoresFerroviarios from '../en-vivo/IndicadoresFerroviarios';
import TramitesLinea from '../en-vivo/TramitesLinea';
import TransformandoNegocios from '../en-vivo/TransformandoNegocios';
import Tmec from '../en-vivo/Tmec';
import VehiculosAltas from '../en-vivo/VehiculosAltas';
import ConversandoSeguridad from '../en-vivo/ConversandoSeguridad';
import ReformasPenales12 from '../en-vivo/ReformasPenales12';
import ReformasPenales11 from '../en-vivo/ReformasPenales11';
import UltimaMillaTelematica from '../en-vivo/UltimaMillaTelematica';
import UltimaMillaPlaneacion from '../en-vivo/UltimaMillaPlaneacion';
import MegatendenciasEsp13 from '../en-vivo/MegatendenciasEsp13';
import MegatendenciasEn13 from '../en-vivo/MegatendenciasEn13';
import MegatendenciasEsp14 from '../en-vivo/MegatendenciasEsp14';
import MegatendenciasEn14 from '../en-vivo/MegatendenciasEn14';
import EnergiasFuturo from '../en-vivo/EnergiasFuturo';

import SeguridadVial from '../en-vivo/SeguridadVial';
import IngenieriaVehicularOriginal from '../en-vivo/IngenieriaVehicularOriginal';
import Admin from '../admin/Admin';
import PreguntasEnVivo from '../admin/PreguntasEnVivo';

import CerrarSesion from '../sesion/CerrarSesion';
import EstadisticaGrafica from '../conferencias/EstadisticaGrafica';

import { AuthContext } from '../../utils/AuthContext';
import Subcontratacion from '../en-vivo/Subcontratacion';
import Ctpat from '../en-vivo/Ctpat';
import Incoterms from '../en-vivo/Incoterms';
import VacunaAnticovid from '../en-vivo/VacunaAnticovid';
import CartaPorte from '../en-vivo/CartaPorte';
import CartaPortev2 from '../en-vivo/CartaPortev2';
import Electromovilidad from '../conferencias/Electromovilidad';
import TvCdfiCartaPorte from '../en-vivo/TvCdfiCartaPorte';
import RegistroSinPago from '../registro/RegistroSinPago';
import SeguridadPrivada from '../en-vivo/SeguridadPrivada';
import Cfdicpp23 from '../en-vivo/CFDICPP23';
import Nom013sct from '../en-vivo/Nom013sct';
import RegistroPago from '../registro/RegistroPago';
import RegistroConstancia from "../registro/RegistroConstancia";

const secciones = [
  
];

const secciones_auth = [
  //{componente:EventoEnvivo, url:'/'},
  {componente:EventoInicio, url:'/'},
  {componente:EventoInicio, url:'/inicio'},
  {componente:Cuestionario, url:'/cuestionario'},
  {componente:Diploma, url:'/constancia'},
  //{componente:RegistroSinPago, url:'/registro/cfdi-cpp-2024'},
   {componente:RegistroPago, url:'/registro/conferencia-seguridad-patrimonial'},

   {componente:RegistroConstancia, url:'/registro/constancia'},
  //{componente:RegistroCerradoAplicacionNom012, url:'/registro/conferencia-seguridad-patrimonial'},
  //{componente:RegistroCerradoAplicacionNom012, url:'/registro/aplicacion-nom-012-febrero'},
  
  //{componente:RegistroMegatendencias, url:'/registro/megatendencias-transporte-maritimo'},
  {componente: RecuperarContrasena, url:'/recuperar'},
  //{componente:EventoEnvivo, url:'/en-vivo'},
  //{componente:VacunaAnticovid, url:'/en-vivo'},
  {componente:CerrarSesion, url:'/cerrar-sesion'},

  {componente:IngenieriaVehicular, url:'/ingenieria-vehicular/conferencias'},
  {componente:AgregandoValor, url: '/agregando-valor/conferencias'},
  {componente:TendenciasPotenciales, url: '/tendencias-potenciales/conferencias'},
  {componente:GuiaLineamientos, url: '/guia-y-lineamientos/conferencias'},
  {componente:ManualPrevencion, url: '/manual-modulo1/conferencias'},
  {componente:GestionEficiente, url: '/gestion-eficiente/conferencias'},
  {componente:ManualPrevencionSegundo, url: '/manual-modulo2/conferencias'},
  {componente:ManualPrevencionTercero, url: '/manual-modulo3/conferencias'},
  {componente:IndicadoresFerroviarios, url: '/indicadores-ferroviarios/conferencias'},
  {componente:TramitesLinea, url: '/tramites-en-linea/conferencias'},
  {componente:TransformandoNegocios, url: '/transformando-negocios/conferencias'},
  {componente:Tmec, url: '/t-mec/conferencias'},
  {componente:SeguridadVial, url: '/seguridad-vial/conferencias'},
  {componente:IngenieriaVehicularOriginal, url: '/ingenieria-vehicular/conferencias'},
  {componente:VehiculosAltas, url: '/vehiculos-alta/conferencias'},
  {componente:ConversandoSeguridad, url: '/conversando-seguridad/conferencias'},
  {componente:ReformasPenales12, url: '/reformas-penales-12/conferencias'},
  {componente:ReformasPenales11, url: '/reformas-penales-11/conferencias'},
  {componente:UltimaMillaTelematica, url: '/ultima-milla-telematica/conferencias'},
  {componente:UltimaMillaPlaneacion, url: '/ultima-milla-planeacion/conferencias'},
  {componente:MegatendenciasEsp13, url: '/megatendencias-13-esp/conferencias'},
  {componente:MegatendenciasEn13, url: '/megatendencias-13-en/conferencias'},
  {componente:MegatendenciasEsp14, url: '/megatendencias-14-esp/conferencias'},
  {componente:MegatendenciasEn14, url: '/megatendencias-14-en/conferencias'},
  {componente:EnergiasFuturo, url: '/energia-del-futuro/conferencias'},
  {componente:Subcontratacion, url: '/desubcontratacion-laboral/conferencias'},
  {componente:Ctpat, url: '/certificacion-ctpat/conferencias'},
  {componente:Incoterms, url: '/incoterms-2020/conferencias'},
  {componente:VacunaAnticovid, url: '/la-vacuna-anticovid/conferencias'},
  {componente:CartaPorte, url: '/carta-porte/conferencias'},
  {componente:Electromovilidad, url: '/la-electromovilidad/conferencias'},
  {componente:CartaPortev2, url: '/carta-porte-2-0/conferencias'},
  {componente:TvCdfiCartaPorte, url: '/taller-vitual-cfdi-carta-porte/conferencias'},
  {componente:SeguridadPrivada, url: '/seguridad-privada-en-la-prevencion/conferencias'},
  {componente:Cfdicpp23, url:'/registro/cfdi-cpp-2024'},
  {componente:Nom013sct, url:'/registro/conferencia-seguridad-patrimonial'},
  {componente:Nom013sct, url:'/conferencia-seguridad-patrimonial-sct-2-2022'},
  
  {componente:Admin, url:'/admin-preguntas-live'},
  {componente:PreguntasEnVivo, url:'/preguntas-en-vivo'},
  {componente:EstadisticaGrafica, url:'/encuesta-envivo'},
  {componente:Error, utl:'/*'}
  
];


export const Master = () => {
  const location = useLocation();
  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  useEffect(() => {
    if (location.hash === "") {
      scroller.scrollTo("header");
    } else {
      scroller.scrollTo(location.hash);
    }
  });

  useEffect(() => {
    sleep(1000).then(() => {
      if (location.hash === "") {
        scroller.scrollTo("header");
      } else {
        scroller.scrollTo(location.hash);
      }
    });
  }, []);

    let visualizar_secciones = secciones.map((seccion)=>{
      return(
        <Route path={seccion.url} exact component={seccion.componente} />
      );
    });

    let visualizar_secciones_auth = secciones_auth.map((seccion)=>{
      return(
        <Route path={seccion.url} exact component={seccion.componente} />
      );
    });

  return(
    <>
    <AuthContext >
    <Switch>
      {visualizar_secciones_auth}
      </Switch>
    </AuthContext>
    </>
  );
}