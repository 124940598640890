import React, { useEffect, useContext, useState, useRef } from "react";
import { Redirect } from 'react-router-dom';

import { Row, Col, Radio, Spin, Modal, Button } from "antd";
import { Auth } from "../../utils/AuthContext";
import { withRouter } from "react-router";
import Firebase from '../../utils/firebaseConfig';
import Live from '../conferencias/Live';
import { Preguntas } from '../conferencias/Preguntas';
import { Estadisticas } from '../conferencias/Estadisticas';
import Crucigrama from '../paginas/Crucigrama';
import '../../assets/css/preguntas_popup.css';

import HeaderIngenieriaVehicular from '../master/header/HeaderIngenieriaVehicular';
import FooterIngenieriaVehicular from '../master/footer/FooterIngenieriaVehicular';

import src_foro from '../../assets/images/webinars/vehiculos_alta_capacidad/03-banner-XXFORO.jpg';
import src_foro_movil from '../../assets/images/webinars/vehiculos_alta_capacidad/banner_movil_foro.jpg';
import src_aniversario from '../../assets/images/webinars/vehiculos_alta_capacidad/03-banner-25ANIVERSARIO.jpg';
import conoce_ponentes from '../../assets/images/eventos/energia_del_futuro/antp_webinar_IniciativaPrivada_Ponentes.jpg';

const nombre_evento = 'conferencia-seguridad-patrimonial';
const app = new Firebase();
let respuesta_elegida = null;
let key = '';
const EventoEnVivo = ({ history }) => {

    const { usuario, datos } = useContext(Auth);
    const [preguntas_ponente, setPreguntasPonente] = useState([]);
    const [etapa, setEtapa] = useState(0);
    const [cargando, setCargando] = useState(false);
    const [preguntas, setPreguntas] = useState([]);
    const [estadisticas, setEstadisticas] = useState([]);
    const [mostrar_pregunta, setMostrarPregunta] = useState(false);
    const [preguntas_pop_up, setPreguntasPopUp] = useState([]);
    const [pregunta_pop_up_activa, setPreguntaPopUpActiva] = useState(0);
    const [respuesta_usuario, setRespuestaUsuario] = useState(null);
    const [datos_crusigrama, setDatosCrusigrama] = useState(null);
    const [key_crusigrama, setKeyCrusigrama] = useState(undefined);
    const [respuestas, setRespuestas] = useState([]);
    const [dia, setDia] = useState(1);
    const [ver_video, setVerVideo] = useState(1);
    const [mostrar_encuesta, setMostrarEncuesta] = useState(false);

    const ref_respuesta_popup = useRef(null);


    const siguienteEtapa = (usuario_respuestas) => {

        key = usuario_respuestas.key;

        switch (etapa) {
            case 0:
                let existe_respuestas = usuario_respuestas.respuestas;
                app.obtener_objetos(nombre_evento + '/preguntas-vivo', false, (datos) => {

                    setPreguntas(datos);
                    app.obtener_objetos(nombre_evento + '/usuarios-encuesta', true, (datos) => {

                        setEstadisticas(datos);
                        setCargando(false);


                        if (typeof existe_respuestas !== 'undefined') {

                            setEtapa(2);
                        } else {

                            setEtapa(1);
                        }


                    });
                })

                break;
            case 1:

                app.obtener_objetos(nombre_evento + '/usuarios-encuesta', true, (datos) => {

                    setEstadisticas(datos);
                    setCargando(false);
                    setEtapa(2);
                });
                break;
            default:
                break;
        }
    }

    const guardarRespuestas = (respuestas) => {

        const actualizar_usuario = {
            correo: datos.correo,
            respondio: true,
            respuestas: respuestas
        }
        setCargando(true);
        app.escribir(nombre_evento + '/usuarios-encuesta', key, actualizar_usuario, siguienteEtapa);

    }

    useEffect(() => {

        if (usuario === null) {
            //window.location.href = "/";
        } else {
            if (datos.correo) {

                if (Object.entries(datos).length !== 0) {
                    app.obtener_objeto(nombre_evento + '/usuarios-encuesta', 'correo', datos.correo, siguienteEtapa);
                }
                app.obtener_objetos(nombre_evento + '/preguntas-publico', true, (data) => {

                    setPreguntasPonente(data);
                });

                app.obtener_objeto(nombre_evento + '/preguntas-popup-publico', 'correo', datos.correo, (data) => {
                    setRespuestaUsuario(data);
                });

                app.obtener_objeto(nombre_evento + '/crusigrama', 'correo', datos.correo, (data) => {
                    setKeyCrusigrama(data["key"]);
                    delete data["key"];
                    delete data["correo"];
                    setDatosCrusigrama(Object.values(data));
                });
            }

        }

    }, [history, usuario, datos]);

    useEffect(() => {
        app.obtener_objetos(nombre_evento + '/preguntas-popup', false, (data) => {
            setPreguntasPopUp(data);
        });
    }, []);

    useEffect(() => {
        app.obtener_objetos(nombre_evento + '/configuracion', true, (data) => {

            setDia(data[0].dia);
            setVerVideo(data[0].ver_video);
            setMostrarEncuesta(data[0].encuesta);
            app.obtener_objetos(nombre_evento + `/preguntas-publico1`, true, (data) => {
                setPreguntasPonente(data);
            });

            app.obtener_objetos(nombre_evento + `/respuestas-admin1`, true, (data) => {
                setRespuestas(data);
            });

            if (data[0].pregunta_popup !== 0) {
                setPreguntaPopUpActiva(data[0].pregunta_popup);
                if (usuario) {

                    if (respuesta_usuario) {
                        if (respuesta_usuario['respuesta' + data[0].pregunta_popup] !== undefined) {
                            setMostrarPregunta(false);
                        } else {
                            setMostrarPregunta(true);
                        }
                    } else {
                        setMostrarPregunta(false);
                    }

                } else {
                    setMostrarPregunta(false);
                }
            } else {
                setPreguntaPopUpActiva(data[0].pregunta_popup);
                setMostrarPregunta(false);
            }

        });



    }, [usuario, respuesta_usuario]);

    const elegirRespuesta = (respuesta) => {
        respuesta_elegida = respuesta;
    }

    const enviarRespuestaPopUp = () => {
        if (respuesta_elegida !== null) {
            let nuevo_respuesta_usuario = Object.assign({}, respuesta_usuario);
            nuevo_respuesta_usuario['respuesta' + pregunta_pop_up_activa] = respuesta_elegida.target.value;
            app.escribir(nombre_evento + '/preguntas-popup-publico', respuesta_usuario.key, nuevo_respuesta_usuario, (data) => {
                setRespuestaUsuario(data);
                respuesta_elegida = null;
                setMostrarPregunta(false);
            })
        } else {

        }
    }

    return (
        <>
            <>
                <HeaderIngenieriaVehicular />

                <div className="cuerpo_vehiculos">
                    <Row justify="" align="">
                        <Col span={24}>
                            <br />
                        </Col>
                        <Col span={24}>
                            {/**https://www.youtube.com/embed/zCdZJoSjqkQ?rel=0*/}
                            <Live
                                url={"https://www.youtube.com/embed/aJDmNZiw1HU?rel=0"}
                                urlyt={"https://youtube.com/live/aJDmNZiw1HU?feature=share"}
                                urlfb={null}
                                urltw={null}
                                webinar={nombre_evento} comentarios={preguntas_ponente} dia={dia} ver_video={ver_video} respuestas={respuestas} />
                            <br /><br />
                        </Col>
                        <Col xs={24} >
                            <br /><br /><br /><br /><br /><br />
                        </Col>
                    </Row>
                    {/* SECCION ENCUESTA */}
                    <Row style={{ display: 'non' }}>
                        <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 2 }} className="contenedor_graficas_">
                            {usuario &&
                                <Row style={{ display: 'non' }}>
                                    <Col span={24}>
                                        <Spin tip="Cargando..." spinning={cargando}>
                                            {mostrar_encuesta &&
                                                (etapa === 1 ?
                                                    (
                                                        <Col span={24} offset={0} style={{ display: 'non' }}>
                                                            <Preguntas preguntas={preguntas} onResponse={guardarRespuestas} />
                                                        </Col>
                                                    )
                                                    :
                                                    (
                                                        <Col span={24} style={{ display: 'non' }}>
                                                            <Estadisticas estadisticas={estadisticas} preguntas={preguntas} />
                                                        </Col>
                                                    )
                                                )
                                            }
                                        </Spin>
                                    </Col>
                                </Row>
                            }
                        </Col>
                        <Col xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} md={{ span: 10, offset: 7 }} lg={{ span: 6, offset: 0 }} className="" style={{ display: 'none' }}>
                            <Row style={{ display: 'none' }}>
                                <Col xs={24} lg={{ span: 23, offset: 1 }}>
                                    <img src={src_aniversario} style={{ width: '100%' }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ display: 'none' }}>
                        {key_crusigrama !== undefined &&
                            <Col xs={{ span: 0, offset: 0 }} lg={{ span: 20, offset: 2 }}>
                                <Crucigrama datos={datos} datos_crusigrama={datos_crusigrama} app_firebase={app} nombre_webinar={nombre_evento} key_crusigrama={key_crusigrama} />
                            </Col>
                        }

                    </Row>
                    <Row>
                        <Col xs={24} lg={0}>
                            <br />
                        </Col>
                        <Col xs={{ span: 0 }} md={{ span: 20, offset: 2 }} style={{ display: 'none' }}>
                            <br /><br />
                            <p className="concoce_ponentes" style={{ display: 'none' }}><strong>CONOCE A LOS PONENTES</strong></p>
                            <hr style={{ backgroundColor: '#8ec549', border: '1px solid #8ec549', display: 'none' }} />
                            <img src={conoce_ponentes} style={{ width: '100%' }} />
                            <br /><br />

                        </Col>
                    </Row>
                </div>

                <FooterIngenieriaVehicular />

                <Modal
                    title="Encuesta"
                    visible={mostrar_pregunta}
                    onOk={() => setMostrarPregunta(false)}
                    onCancel={() => setMostrarPregunta(false)}
                    footer={null}
                    width='600px'
                >

                    <>

                        {preguntas_pop_up.filter(pregunta => {
                            if (pregunta.num_pregunta === pregunta_pop_up_activa) {
                                return true;
                            } else {
                                return false;
                            }
                        }).map(pregunta => {
                            return (
                                <p className="texto_pregunta_popup">{pregunta.pregunta}</p>
                            );
                        })}



                        {
                            preguntas_pop_up.filter(pregunta => {
                                if (pregunta.num_pregunta === pregunta_pop_up_activa) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }).map(pregunta => {

                                let respuestas = Object.values(pregunta.respuestas).map(respuesta => {
                                    return (
                                        <Radio className="texto_respuesta_popup" value={respuesta.respuesta}>
                                            {respuesta.respuesta}
                                        </Radio>
                                    );
                                });

                                return (
                                    <Radio.Group onChange={elegirRespuesta}>
                                        {respuestas}
                                    </Radio.Group>
                                );
                            })

                        }

                        <br />
                        <p style={{ textAlign: 'right' }}>
                            <Button onClick={enviarRespuestaPopUp}>Enviar</Button>
                        </p>

                    </>




                </Modal>
            </>







        </>
    );

}

export default EventoEnVivo;