import React, { useEffect, useContext, useState } from "react";

import { Button, Menu, Row, Col, Typography, Spin } from "antd";
import { Auth } from "../../utils/AuthContext";
import '../../assets/css/sala.css';

import HeaderIngenieriaVehicular from '../master/header/HeaderIngenieriaVehicular';
import FooterIngenieriaVehicular from '../master/footer/FooterIngenieriaVehicular';

const Nom013sct = ({ history }) => {

    const { usuario } = useContext(Auth);

    return (
        <>
            <HeaderIngenieriaVehicular usuario={usuario} />
            <div className="cuerpo">
                <Row justify="center" align="">

                    <Col span={18}>
                        <br /><br />
                        <iframe className="video2" width="100%" src="https://www.youtube.com/embed/aJDmNZiw1HU?autoplay=1&rel=0;" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Col>
                    <Col span={18}>
                        <br />
                        <p style={{textAlign:'left', color:'white', fontSize:'16px'}}>
                        <strong>Consulta más información <a href="https://kinedyne.com.mx/conferencia-seguridad-patrimonial-sct-2-2022-1/" target="_blank">aquí</a></strong>
                        </p>
                        <br />
                        
                    </Col>
                </Row>

            </div>

            <FooterIngenieriaVehicular />
        </>
    );

}

export default Nom013sct;