import React, { Component } from "react";
import Slider from "react-slick";
import '../../assets/css/ingenieria/evento.css';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Contador from  './Contador';

import imagen_geader from '../../assets/images/cicloconferencias_bannerweb_3.jpg';
import imagen_geader_movil from '../../assets/images/cicloconferencias_bannerweb_movil_3.jpg';
import flujo from '../../assets/images/antp_Nom012_BannerContenido.png';

export default class CarouselPrincipal extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1,
      autoplaySpeed: 5000,
      cssEase: "linear"
    };
    return (
      <div>
        <Slider {...settings}>
        <div>
        <div justify="center" style={{display:'non'}}>
            <Row>
              <Col xs={24} lg={0}>
                <Link to="/registro/conferencia-seguridad-patrimonial">
                  <img src={imagen_geader_movil} style={{width:'100%'}} />
                </Link>
              </Col>
              <Col xs={0} lg={24}>
                <Link to="/registro/conferencia-seguridad-patrimonial">
                  <img src={imagen_geader} style={{width:'100%'}} />
                </Link>
              </Col>
            </Row>
          </div>
        </div>
        </Slider>
      </div>
    );
  }
}